import React from "react"
import ContactUsForm from "../components/Forms/ContactUsForm"
import SEO from "../components/utils/seo"
import ContactInfo from "../components/Partials/ContactInfo"
import { MaxWidthInnerContainer } from "../styles/globalCss"
import { graphql } from "gatsby"
import { HeaderText, BodyText } from "../components/Partials/TextPieces"
import { get } from "lodash"
import styled from "@emotion/styled"
import BackgroundImage from "../components/Partials/BackgroundImage"
import Spacer from "../components/Partials/Spacer"

const Contact = ({ data }) => {
    const {
        backgroundImage: { fluid = {}, description = "" } = {},
        mainHeader = "",
        pageDescription
    } = get(data, "contentfulContactPage", {})

    const { html: clubHoursDetails } = get(
        pageDescription,
        "childMarkdownRemark",
        ""
    )

    return (
        <>
            <SEO title="Contact" />
            <BackgroundImage bkgImg={fluid} description={description} />
            <MaxWidthInnerContainer>
                <ContentWrapper>
                    <Spacer size="xsmall" />
                    <ContactInfo contactPage />
                    <Spacer size="medium" />
                    <HeaderText copy={mainHeader} />
                    <BodyTextContainer>
                        <BodyText
                            copy={clubHoursDetails}
                            padding
                            noMarginTop
                            extraLineheight
                        />
                    </BodyTextContainer>
                </ContentWrapper>
            </MaxWidthInnerContainer>
            <ContactUsForm bkgImg={fluid} description={description} />
        </>
    )
}

export default Contact

export const query = graphql`
    query {
        contentfulContactPage {
            mainHeader
            pageDescription {
                childMarkdownRemark {
                    html
                }
            }
            backgroundImage {
                description
                fluid(maxWidth: 1440) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }
`

const ContentWrapper = styled.div`
    margin: 60px 0;
    display: inline-block;
    width: 100%;
`

const BodyTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 2.2;
`
